<!--script setup>
import { useRoute } from 'vue-router';
const route = useRoute();   // Get the current route
</script-->

<template>
  <header>
    <div id="header-top">AI is power. <span id="motto-1">AI is the fulcrum.</span></div>
    <div id="mid-header">
      <router-link to="/" id="logo-ref"><img src="/img/logo.png" alt="AI Directly" id="logo" /></router-link>
      <h1 v-if="['/immigrun', '/immigform', '/cvbuild', '/bizplan', '/coupon'].includes($route.path.toLowerCase())">Immigration Run</h1>
      <div v-else id="gpt-bar">
        <input id="gpt-bar-input" type="text" placeholder="Ask our GPT..." autocomplete="off" onkeyup="if(event.key === 'Enter') window.useGPTBar();" />
        <button id="gpt-bar-send-btn" aria-label="Send" onclick="window.useGPTBar()">
          <font-awesome-icon :icon= "['far', 'message']" />
        </button>
      </div>
      <nav id="menu">
        <ul>
          <li @click="toggleDropdown">
            <a href="#" class="menu-tag">uPortal</a>
            <div v-if="showDropdown" ref="portalDropdown" class="menu-dropdown" id="portal" @click.stop>
              <form @submit.prevent="signIn">
                <input type="text" v-model="username" placeholder="Username" id="portal-username">   <!--this id="portal-username" is not for programming, but letting browser memorize input history-->
                <input type="password" v-model="password" placeholder="Password">
                <button type="submit">Sign In</button>
              </form>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <div id="header-drape">
      <div class="gpt-reply">
        <div class="gpt-msg motto">
          We help clients integrate into the AI revolution and excel beyond innovation.
        </div>
      </div>
      <div v-if="$route.path === '/'" class="gpt-reply">
        <div class="gpt-msg motto">
          Our GPT systems have been <strong>generating labor</strong> for clients to
          <ul>
            <li>Boost efficiency and productivity</li>
            <li>Scale up with new capacity</li>
            <li>Cut costs</li>
          </ul>
        </div>
      </div>
    </div>
  </header>

  <main>
    <router-view />

    <section id="report"></section>
  </main>

  <footer>
    <div id="gpt-icon" onclick="window.toggleChat()">
      Chat<br>GPT
    </div>
    <div id="gpt-box">
      <div id="gpt-resize-handle" onmousedown="window.resizeChatbox(event)"></div>
      <div id="gpt-header" onclick="window.toggleChat()">
        AI-Directly Master
        <button id="gpt-close-btn">&#x2715;</button>
      </div>
      <div id="gpt-body">
        <div id="gpt-messages">
          <div class="gpt-reply">
            <div class="gpt-avatar-self"></div>
            <div class="gpt-self">Min:</div>
            <div class="gpt-msg">
              <span>Welcome to AI Directly. How can I assist you today? You can ask any question in any language. I am here tirelessly serving you better.</span>
            </div>
          </div>
        </div>
        <div id="gpt-oper">
          <input type="text" id="gpt-input" placeholder="Type a message..." autocomplete="off" onkeyup="if(event.keyCode === 13) window.dialogue()">
          <button onclick="window.dialogue()" id="gpt-send-btn" aria-label="Send">
            <span id="gpt-btn-text">Send</span>
            <span id="gpt-btn-symbol"><font-awesome-icon :icon="['far', 'paper-plane']" /></span>
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import '@/util/chatbox.css';
import logShow from './util/chatReport';

export default {
  name: 'App',
  data() {
    return {
      showDropdown: false,
      timdout: null,
      username: '',
      password: ''
    };
  },

  /* the 3 function below handles the click on a link in index.html or provided by GPT. While the link is internal, it shall be routed by VUE */
  mounted() {    // tends to screen click on links that can be routed by VUE
    document.getElementById('gpt-messages').addEventListener('click', this.handleClicking);
    document.getElementById('about-us').addEventListener('click', this.handleClicking);
  },

  beforeUnmount() {
    document.getElementById('gpt-messages').removeEventListener('click', this.handleClicking);
    document.getElementById('about-us').removeEventListener('click', this.handleClicking);
  },

  methods: {
    handleClicking(event) {
      const target = event.target;
      if (target.tagName === 'A') {      // Ensure that the clicked element is an anchor tag
        event.preventDefault();  // Prevent the default link behavior
        
        const href = target.getAttribute('href');
        if (href && href.includes('ai-directly.com')) {
          const url = new URL(href);
          const path = url.pathname;  // Extract the path from the URL
          const hash = url.hash;    // extract the hash from the URL
          this.$router.push({ path: path, hash: hash });          // Navigate using Vue Router and include the hash

          // the following add-on specifically accormodate the mechanic that the card details are displayed and removed
          const details = document.getElementsByClassName('card-detail');
          for (let i = 0; i < details.length; i++) {
            details[i].style.display = "none";
          }

        } else {
          window.open(href, '_blank');          // External link, open in a new tab
        }
      }
    },

    /* the code below deals with the menu behavior */
    toggleDropdown() {
      const trunks = document.querySelectorAll('.trunk');
      if (trunks[0].style.display === 'none') {      // report is on, then switch back to normal webpage
        document.getElementById('report').innerHTML = '';
        trunks.forEach(trunk => {
          trunk.style.display = 'block';
        });
      } else {
        this.showDropdown = !this.showDropdown;

        // Clear any existing timer before setting a new one
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        // If `showDropdown` is true, set a timer to change it to false after several seconds
        if (this.showDropdown) {
          this.timeout = setTimeout(() => {
            if (!this.isDropdownFocused()) {
              this.showDropdown = false;
            }
          }, 4000);   // 4 seconds
        }
      }
    },

    // Helper method to check if the dropdown has focus
    isDropdownFocused() {
      const dropdown = this.$refs.portalDropdown;
      if (dropdown && document.activeElement) {
        // Check if the active element is the dropdown or one of its descendants
        return dropdown === document.activeElement || dropdown.contains(document.activeElement);
      }
      return false;
    },

    //
    signIn() {
      const url = new URL(`${process.env.VUE_APP_API_URL}/chat/log`);
      url.search = new URLSearchParams({ com: this.username, days: 7 });

      fetch(url, {
        method: 'GET',
        headers: { key: this.password }
      })
        .then(response => {
          if (!response.ok) {
            alert('App01, Sign-in failed.');
            throw new Error('CBvvi');   // vvi is just randomly a roman number. Matching is the only purpose.
          }
          return response.json();
        })
        .then(data => {
          this.showDropdown = false;

          // make other <!--body--> part invisible
          document.querySelectorAll('.trunk').forEach(trunk => {          
            trunk.style.display = 'none';
          });

          // build the report
          logShow(this.username, data);
        })
        .catch(error => {
          if (error.message !== 'CBvvi')   // due to above !OK problem
            alert('App02, An error occurred during sign-in.');
        });
    },
  }
}
</script>

<style>
/* Global styles */

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/**********/
/* header */
/**********/
header {
  background: url('@/assets/background-2.jpg') no-repeat center center;
  background-size: cover;
  padding-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  header {
    background-position: center -280px;    /* Crop by shifting up */
    background-attachment: fixed;     /* Makes the background image freeze */
  }
}

/*------------*/
/* header-top */
/*------------*/
#header-top {
  padding-right: 50px;
  color: white;
  text-align: right;
  font-weight: bold;
}

@media screen and (min-width: 1024px) {
    #header-top {
    background-color: #fff8;
    color: #161f28;
    font-weight: normal;
  }
}

@media screen and (max-width: 1023px) {
  #motto-1 {
    display: none;
  }
}

/*------------*/
/* mid-header */
/*------------*/
#mid-header {
  position: relative;  /* Set the position to relative for absolute positioning of the nav */
  display: flex;
  align-items: center;
  justify-content: space-between;  /* Separate logo and menu */
  background-color: #fbf0e6;
}

#logo {
  display: block;   /* this make sure the space below #logo dissapear */
  height: 76px;
  margin: 0;
}

h1 {
  display: none;
}

@media screen and (min-width: 1024px) {
  h1 {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);    /* Center precisely by offsetting its own width */
    margin: 0;
    padding: 0;
    font-size: 1.8em;
    font-weight: 500;
    color: #bbb;
    letter-spacing: 1.5px;
    line-height: 1;
    text-transform: uppercase;
    text-shadow: 
      1px 1px 0 rgba(255, 255, 255, 0.7),   /* Slight highlight on bottom-right edges */
      -1px -1px 1px rgba(0, 0, 0, 0.4);     /* Slight shadow on top-left edges */;
  }

  h1::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background-color: #ff7f50; /* accent color */
    margin: 12px auto 0;
    border-radius: 2px;
  }
}

@media screen and (min-width: 1024px) {
  #logo-ref {
    margin-left: 18%;
    padding: 0;
  }

  #logo {
    height: 100px;
  }
}

#menu {
  position: absolute;
  right: 19%;
  bottom: 10px;
  font-size: large;
  font-weight: bold;
}

#menu ul {
  list-style-type: none;
  margin: 0;
  display: flex;  /* Display the menu items in a row */
}

#menu li {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
}

.menu-tag, .menu-tag:visited {
  text-decoration: none;
  color: initial;
}

.menu-tag:hover {
  color: #004b7a;
}

.menu-dropdown {
  position: absolute;
  right: -12px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  background-color: #fbf0e6;
  margin-top: 10px;
  padding: 12px;
  display: block;
}

#portal input[type="text"],
#portal input[type="password"] {
  padding: 10px;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

#portal button {
  width: 100%;
  padding: 10px;
  background-color: #004b7a;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 1023px) {
  #menu {
    right: 10px;
    bottom: 2px;
    font-size: medium;
  }

  .menu-dropdown {
    right: -8px;
    margin-top: 2px;
    padding: 8px;
  }
}

/*--------------*/
/* header-drape */
/*--------------*/
#header-drape {
  height: auto;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
  background-color: #fff3;
}

.motto {
  margin-top: 16px;
  color: white;
  background-color: #004b7ad0 !important;
  line-height: 1.5;
}

.motto ul {
  margin-top: 2px;
  margin-bottom: 2px;
}

@media screen and (min-width: 1024px) {
  #header-drape {
    width: 60%;
    margin: 20px 18% 10px 18%;
    padding: 4px 36px 24px;
    border: 1px solid #ccc;
  }

  .motto {
    padding: 20px 48px 14px 24px;
    border-bottom-left-radius: 0;
    font-family: Verdana, sans-serif;
  }
}

@media screen and (max-width: 1023px) {
  #header-drape {
    width: 94%;
    margin: 0 0 10px 0;
    padding: 6px 16px;
    border: 0;
  }

  .motto {
    padding-right: 12px;
  }
}


/**************/
/* for report */
/**************/
.report-client {
  border: 2px solid #ccc;
  border-radius: 10px 10px 2px 2px;
  margin: 20px 2%;
}

@media screen and (max-width: 1023px) {
  .report-client {
    margin: 12px 2px;
  }
}
</style>