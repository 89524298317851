<template>
    <section id="lead-validator" class="trunk">
        <iframeCompo :key="coid" coid="LeadVal" email_subject="Customer Service AI" :enable_passcode="false">
            <template #foreword>
                <h2>Sales Lead Validator</h2>
                <p>
                    Please paste the job description or just the web address of the JS into the input box below. This function validates and evaluates 
                    whether the JD implies a demand for our FirmGPT and, if likely, generates a pitch letter for you to copy-and-paste.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import iframeCompo from "../components/iframeCompo.vue";

export default {
    name: 'LeadvalView',
    components: {
        iframeCompo,
    },
}
</script>

<style scoped>

/********* */
/* Form UI */
/********* */
form select {
    width: max-content;
    margin: 0 12px;
    padding: 8px 12px;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 3px;
    background-color: transparent;
    font-size: inherit;
    cursor: pointer;
}
</style>