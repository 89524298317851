<template>
    <span class="gpt-tooltip" @click="tooltipping">
        <slot name="trigger"></slot>
    </span>
</template>

<script>
export default {
    name: 'iframeTooltip',
    props: {
        tooltip_q: {        // Question
            type: String,
            required: true
        },
        tooltip_a: {        // Answer
            type: String,
            required: false
        }
    },
    setup(props) {
        const tooltipping = (() => {
            const iframe = document.getElementById('page-frame');
            iframe.contentWindow.postMessage({ tooltip_q: props.tooltip_q, tooltip_a: props.tooltip_a }, process.env.VUE_APP_API_URL);
        });

        return { tooltipping };
    }
}
</script>

<style scoped>
.gpt-tooltip {
    color: #0074d9;
    cursor: pointer;
}

.gpt-tooltip:hover {
    text-decoration: underline;
}
</style>
