<template>
    <div id="op-wrap">
        <router-link to="/">
            <button>
                <span v-if="lang.includes('cn')" class="lang-cn">首页</span>
                <span v-else>Home</span>
            </button>
        </router-link>

        <!--select :value="page" @change="handleRoute"-->
        <select :value="page" @change="$router.push({ path: $event.target.value, query: { lang: (lang === 'en') ? undefined : lang } })">
            <option value="/immigrun">
                <span v-if="lang.includes('cn')" class="lang-cn">“润博士”</span>
                <span v-else>ImmigRun</span>
            </option>
            <option value="/cvbuild">
                <span v-if="lang.includes('cn')" class="lang-cn">简历精灵</span>
                <span v-else>ResumeGPT</span>
            </option>
            <option value="/bizplan">
                <span v-if="lang.includes('cn')" class="lang-cn">生意规划</span>
                <span v-else>BizPlan</span>
            </option>
            <option value="/immigform">
                <span v-if="lang.includes('cn')" class="lang-cn">服务发布</span>
                <span v-else>ServicePost</span>
            </option>
        </select>

        <!-- This way the props.lang can be bound and its value can be emit back to parents -->
        <!-- So the props has to be a variable, otherwise the corresponding v-if in this component won't even work. -->
        <select :value="lang" @change="$emit('update:lang', $event.target.value)">
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="cn" class="lang-cn">简体中文</option>
            <option value="tcn" class="lang-cn">繁體</option>
            <option value="kor">한국어</option>
            <option value="es">Español</option>
            <option value="pt">Português</option>
            <option value="far" class="lang-far">فارسی</option>
        </select>
    </div>
</template>

<script>
//import { nextTick } from 'vue';
//import { useRoute, useRouter } from 'vue-router';

export default {
    name: 'pageCtrl',
    props: {
        lang: {
            type: String,
            required: true
        },
        page: {
            type: String,
            required: true
        }
    },
    /*setup(props, { emit }) {
        const route = useRoute();       // Get the current route
        const router = useRouter();     // Router instance

        // note: when postDisp is true, the <iframe> is not visible, so event.date.frame_height won't update along content change such while the language changes
        // therefore postHeight() needs to be triggered in the <iframe> content when handleRoute() back to <iframe>
        const handleRoute = (event) => {
            //$event.target.value has 3 values: '/immigrun', '/cvbuild', 'post'
            switch (event.target.value) {
                case '/cvbuild': {
                    router.push({
                        path: '/cvbuild',
                        query: { lang: props.lang },
                    });
                    return;
                }
                case '/immigrun': {
                    if (route.path !== '/immigrun') {       // the flow is from /cvbuild to /immigrun
                        router.push({
                            path: '/immigrun',      // or use `name` if you're navigating by route name
                            query: { lang: props.lang },
                        });
                        return;
                    }
                    else {
                        emit('update:page', '/immigrun');

                        // While page === 'post', the <iframe> is not visible, so in iframeCompo.vue, the event.data.frame_height won't update along content change such while the language changes
                        // The content in <iframe> may have updated for many times and <iframe> height haven't caught up
                        // Then later when page !== 'post', <iframe> back to visible, postHeight() needs to be triggered in the <iframe> content
                        // So, we have to trigger postHeight() in aidi-func.js in <iframe> after the browser renders the content html.
                        // The triggering moment is set by this nextTick()
                        nextTick(document.getElementById("page-frame").contentWindow.postMessage({}, '*'));
                        break;
                    }
                }
                case 'post': {
                    if (route.path !== '/immigrun') {       // the flow is from /cvbuild to /immigrun
                        router.push({
                            path: '/immigrun',      // or use `name` if you're navigating by route name
                            query: { page: 'post', lang: props.lang },
                        });
                        return;
                    }
                    else {
                        emit('update:page', 'post');
                        //nextTick(document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' }));
                    }
                }
            }

            // when page === 'post', the <iframe> is not visible, so in iframeCompo.vue, the event.data.frame_height won't update along content change such while the language changes
            // therefore when page !== 'post', <iframe> back to visible, postHeight() needs to be triggered in the <iframe> content
            // Below is the scenario that 'page' governs the toggle between normal and post page within immigView
            nextTick(() => {
                if (props.page === 'post') {
                    document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' });
                } else {
                    // The content in <iframe> may have updated for many times and <iframe> height haven't caught up
                    // So, we have to trigger postHeight() in aidi-func.js in <iframe> after the browser renders the content html.
                    // The triggering moment is set by this nextTick()
                    document.getElementById("page-frame").contentWindow.postMessage({}, '*');
                }
            });
        }

        return { handleRoute };
    }*/
}
</script>

<style>

/****************** */
/* Language control */
/****************** */
.lang-cn,
.lang-cn p,
.lang-cn h2,
.lang-cn h3 {
    letter-spacing: 0.06em;
}

.lang-cn u {
    text-decoration: none;
    box-shadow: 0 1px 0 0 gray;
}

.lang-far p,
.lang-far h2,
.lang-far h3 {
    text-align: right;
}

.lang-far h3,
.lang-far p {
    font-size: larger;
}
</style>


<style scoped>

/************* */
/* Control bar */
/************* */
#op-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 12px 6% 0 0;
}

#op-wrap select,
#op-wrap button {
    width: 120px;
    height: 32px;
    margin: auto 8px;
    padding: 4px;
    background-color: #fbf0e6;
    border: 1px dashed lightgray;
    border-radius: 6px;
    box-shadow: none;
    color: #004b7a;
    font-family: Verdana, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

#op-wrap button {
    width: 90px;
}

#op-wrap select:focus,
#op-wrap button:focus {
    outline: none;
    border-color: #004b7ae0;
}

@media screen and (max-width: 1023px) {
    #op-wrap {
        margin-right: 2%;
    }

    #op-wrap select,
    #op-wrap button {
        width: 99px;
        height: 30px;
        margin-right: 2px;
        padding-right: 2px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: normal;
    }

    #op-wrap button {
        width: 80px;
    }
}
</style>
