<template>
    <section id="coupon" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/coupon'" @change="cnDisp = curLang.includes('cn')" />

        <div id="immig-form">
            <div v-if="curLang === 'en'">
                <h2>Service Profile Submission Form</h2>
                <p>
                    For businesses serving new immigrants and international students, you are invited to post your services using the form below and subscribe to the promotion service delivered by this AI Consultant.
                </p>
                <p>
                    Once you submit the form, our AI will extract information from your company website and create a company profile, making it inclusive for future retrieval as part of AI-driven promotions.
                </p>
            </div>
            <div v-if="curLang === 'fr'">
                <h2>Formulaire de Soumission de Profil de Service</h2>
                <p>
                    Pour les entreprises qui desservent les nouveaux immigrants et les étudiants internationaux, vous êtes invités à lister vos services en utilisant le formulaire ci-dessous et à souscrire au service de promotion fourni par cet AI Consultant.
                </p>
                <p>
                    Une fois que vous aurez soumis le formulaire, notre intelligence artificielle extraira des informations de votre site Web et créera un profil d'entreprise, l'incluant pour une utilisation future dans le cadre de promotions pilotées par l'IA.
                </p>
            </div>
            <div v-if="curLang === 'cn'" class="lang-cn">
                <h2>服务商信息发布申请表</h2>
                <p>
                    服务于移民留学人群的上下游公司请用下表申请发布服务内容并订购AI推介。
                </p>
                <p>
                    在您提交申请后, 我们的人工智能将从您公司网站提取信息, 存档后作为人工智能将来的检索来源和推介参考。
                </p>
            </div>
            <div v-if="curLang === 'tcn'" class="lang-cn">
                <h2>服務商信息發布申請表</h2>
                <p>
                    為新移民與留學生提供服務的上下游公司，請使用下列表格申請發布服務內容，並訂購AI推介。
                </p>
                <p>
                    提交後，我們的人工智慧將會從您公司網站提取資訊，並建立公司檔案，作為未來人工智慧推廣和檢索的依據。
                </p>
            </div>
            <div v-if="curLang === 'kor'">
                <h2>서비스 제공자 정보 게시 신청서</h2>
                <p>
                    이민자 및 유학생을 대상으로 서비스를 제공하는 관련 기업은 아래 양식을 사용하여 서비스 내용을 신청하고, AI 추천을 구독해 주세요.
                </p>
                <p>
                    제출 후, 저희 AI가 귀사의 웹사이트에서 정보를 추출하고 이를 바탕으로 향후 AI 기반 검색과 추천에 참고할 수 있는 회사 프로필을 생성할 것입니다.
                </p>
            </div>
            <div v-if="curLang === 'es'">
                <h2>Formulario de Envío de Perfil de Servicio</h2>
                <p>
                    Para las empresas que atienden a nuevos inmigrantes y estudiantes internacionales, están invitadas a listar sus servicios utilizando el formulario a continuación y a suscribirse al servicio de promoción ofrecido por este Consultor de IA.
                </p>
                <p>
                    Una vez que envíen el formulario, nuestra IA extraerá información de su sitio web y creará un perfil de empresa, haciéndolo accesible para futuras consultas como parte de las promociones impulsadas por IA.
                </p>
            </div>
            <div v-if="curLang === 'pt'">
                <h2>Formulário de Submissão de Perfil de Serviço</h2>
                <p>
                    Para as empresas que atendem novos imigrantes e estudantes internacionais, você está convidado a listar seus serviços usando o formulário abaixo e a assinar o serviço de promoção oferecido por este Consultor de IA.
                </p>
                <p>
                    Após o envio do formulário, nossa IA irá extrair informações do site da sua empresa e criar um perfil corporativo, tornando-o disponível para futuras consultas como parte das promoções baseadas em IA.
                </p>
            </div>
            <div v-if="curLang === 'far'" class="lang-far">
                <h2>فرم درخواست انتشار اطلاعات ارائه دهنده خدمات</h2>
                <p>
                    برای کسب‌وکارهایی که به مهاجران جدید و دانشجویان بین‌المللی خدمات ارائه می‌دهند، از شما دعوت می‌شود که با استفاده از فرم زیر خدمات خود را فهرست کنید و از خدمات تبلیغاتی ارائه شده توسط این مشاور هوش مصنوعی استفاده کنید.
                </p>
                <p>
                    پس از ارسال فرم، هوش مصنوعی ما اطلاعات را از وب‌سایت شرکت شما استخراج کرده و یک پروفایل شرکت ایجاد می‌کند که برای بازیابی‌های آینده به عنوان بخشی از تبلیغات مبتنی بر هوش مصنوعی قابل استفاده خواهد بود.
                </p>
            </div>
            <form @submit.prevent="submitForm">
                <p>
                    <label>
                        <span v-if="!cnDisp">*Contact:</span>
                        <span v-else class="lang-cn">*联系人:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.name" placeholder="Full name like Mr. John Doe" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Phone:</span>
                        <span v-else class="lang-cn">* 手机:</span>
                    </label>
                    <input class="input-full" type="tel" v-model="agent.phone" pattern="\+[0-9]{1,3}[-\s]?[(]?[0-9]{1,5}[)]?[-\s]?[0-9]{3,4}[-\s]?[0-9]{4}" placeholder="Cell # like +1 (416) 123-4567" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Email:</span>
                        <span v-else class="lang-cn">* 电邮:</span>
                    </label>
                    <input id="email-input" class="input-full" type="email" v-model="agent.email" placeholder="Business email" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Passcode:</span>
                        <span v-else class="lang-cn">*验证码:</span>
                    </label>
                    <span class="input-full">
                        <input type="number" v-model="agent.ecode" placeholder="received from email" required />
                        <button type="button" v-if="!cnDisp" @click="requestPasscode">Request Passcode</button>
                        <button type="button" v-else @click="requestPasscode">获取验证码</button>
                    </span>
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">*Website:</span>
                        <span v-else class="lang-cn">* 网址:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.website" placeholder="Company url, like abc.com/about" required />
                </p>
                <p>
                    <label>
                        <span v-if="!cnDisp">Caption:</span>
                        <span v-else class="lang-cn">简注:</span>
                    </label>
                    <input class="input-full" type="text" v-model="agent.caption" placeholder="Keyword-based narration (Max 384 bytes)" maxlength="100" />
                </p>
                <p v-if="submitting" style="justify-content: center;">
                    <img src="@/assets/spinnerBlue.gif" alt="Wait..." style="width: 80px" />
                </p>
                <p v-if="timestamp" style="margin-top: 32px;">
                    &emsp; <i class="fa fa-check" style="color: green; font-size: x-large;"></i> 
                    &emsp; {{ timestamp }}
                    &emsp; <span style="text-decoration: underline;">Your submission is successfully accepted and will be processed shortly.</span>
                </p>
                <div class="btn-container">
                    <button type="submit">
                        <span v-if="!cnDisp">Submit</span>
                        <span v-else>提 交</span>
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import { ref, reactive, nextTick, onMounted } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { DateTime } from 'luxon';
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'CouponView',
    components: {
        pageCtrl
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');
        const cnDisp = ref(curLang.value.includes('cn'));

        const agent = reactive({
            name: '',
            phone: '',
            email: '',
            ecode: '',      // email passcode, potentially there could be a SMS passcode called tcode
            website: '',
            caption: ''
        });
        
        const timestamp = ref('');
        const submitting = ref(false);

        const requestPasscode = (() => {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!agent.email || !pattern.test(agent.email)) {
                alert(`IV01: Invalid email address.`);
                return;
            }
                
            // disable the Email input first
            document.getElementById('email-input').disabled = true;

            //
            fetch(`${process.env.VUE_APP_API_URL}/mfacode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', tag: agent.email })
            })
            .then(response => {
                if (response.ok) {
                    alert(`A passcode has been sent to your email box.\n\nIt expires in ${process.env.VUE_APP_PASSCODE_LIFECYCLE} minutes.`);
                } else {  
                    alert(`IV02: ${response.status} - ${response.statusText}\n\nDetails: ${JSON.stringify(response.json())}`);
                }
            })
            .catch(error => {
                alert(`IV03: Request failed due to networking issues. Please try again later.\n\n${error.message}`);
            });
        });

        const submitForm = (() => {
            if (submitting.value) {    // because the server response takes time, multi submissions during this period shalll be prevented
                return;
            } else {
                submitting.value = true;
            }

            // validate the length of agent.caption, which could be in a language different from English
            const byteBuf = new TextEncoder().encode(agent.caption);
            if (byteBuf.length > process.env.IMMIG_FORM_CAPTION_LEN) {
                alert("IF11: The Caption input is too long.");
                return;
            }

            // validate the value of agent.website
            try {
                let { website } = agent;
                if (!website.startsWith('https://') && !website.startsWith('http://')) {
                    website = 'https://' + website;
                }
                new URL(website);     // validate the url input for the Website, otherwise an error may occur
                agent.website = website;

            } catch (_) {       // catches the error on 'new URL()'
                alert("IF12: Invalid URL for the Website input.");
                return;
            }

            // proceed to the server
            fetch(`${process.env.VUE_APP_API_URL}/chat/immiform`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: 'ImmigRun', ...agent })
            })
            .then(response => {
                if (response.ok) {
                    timestamp.value = `${DateTime.now().setZone("America/Toronto").toFormat('yyyy-MM-dd, HH:mm:ss')} EDT`;
                    //agent = { name: '', phone: '', email: '', ecode: '', website: '', caption: '' };     // reset the <form>
                    // in order to maintain the reactivity of 'agent', reset the <form> using the following way
                    for (const key in agent) {
                        agent[key] = '';
                    }

                    nextTick(() => {
                        document.getElementById('immig-form').scrollIntoView({ behavior: 'smooth', block: 'end' })
                    });
                } else {              // This tends to handle HTTP status codes that are in 400 and 500 families, but may also include that in the uncommon 100 and 300 families
                    const msg = (response.status < 500)     // processing 4xx and 5xx
                        ? `Failed to submit. Please correct your data or maybe shorten it and try again.` 
                        : `Submission failed due to a server error. Please inform info@ai-directly.com or try it later.`;

                    alert(`IF13: ${response.status} - ${response.statusText}\n\n${msg}\n\n${ JSON.stringify(response.json()) }`);
                }
            })
            .catch(error => {       // catches the error from 'fetch'
                alert(`IF14: Submission failed due to network errors. Please try again later.\n\n${error.message}`);
            })
            .finally(() => {
                submitting.value = false;
            });
        });

        // to do when mounting
        onMounted(() => {
            document.getElementById('gpt-icon').style.display = 'none';
            document.getElementById('gpt-box').style.display = 'none';
            document.getElementById('about-us').style.display = 'none';
        });

        // to do at leaving
        onBeforeRouteLeave((to, from, next) => {
            document.getElementById('gpt-icon').style.display = 'block';
            document.getElementById('about-us').style.display = 'block';
            next();
        });

        return { curLang, cnDisp, agent, submitting, timestamp, requestPasscode, submitForm };
    },
}
</script>

<style scoped>
h2 {
    margin-top: 16px;
}

/********* */
/* Form UI */
/********* */
#immig-form {
    padding-bottom: 32px;
}

form {
    max-width: 1024px;
    padding: 16px 0;
}

form p {
    display: flex;    /* Enables flexbox */
    align-items: center;    /* Centers items vertically */
    width: 100%;
}

form label {
    flex: 20%;    /* Does not grow, does not shrink, takes up 20% of the space */
    padding-right: 16px;
    text-align: right;
}

form input {
    padding: 15px 12px 13px 12px;
    border: 1px solid lightgray;
    border-radius: 3px;
    font-size: inherit;
}

form input:focus {
    outline: none;
    border-color: #004b7ae0;
}

.input-full {
    flex: 80%;    /* Takes the remaining space from <label> */
}

span.input-full {
    flex: 83%;
    display: inline-flex;
}

.input-full input {
    flex: 60%;    /* Takes the partial space within .input-full */
    margin: 0 12px 0 0;
}

.input-full button {
    flex: 40%;
    background-color: #fbf0e6;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.2s ease;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
}

.input-full button:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}


.input-full button:active {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
    transform: translateY(2px);
    background-color: #f0e0d0; /* Slightly darker shade when pressed */
}

.btn-container {
    display: flex;
    justify-content: center;
}

.btn-container button {
    width: 38%;
    margin-top: 40px;
    padding: 13px 10px 11px 10px;
    border-color: gray;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(64, 64, 64, 0.1);
    transition: all 0.3s ease;
    background: #004b7ae0;
    color: white;
    cursor: pointer;
    font-size: inherit;
    place-items: center;
}

.btn-container button:hover {
    border-color: lightgray;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    background: linear-gradient(135deg, #004b7ad0, #004b7af0);
}

.btn-container button:disabled {
    cursor: not-allowed;     /* Cursor style to indicate it's not clickable */
}

@media screen and (max-width: 1023px) {
    form label {
        flex: 24%;
        padding-right: 6px;
    }

    form input {
        padding: 10px;
    }

    .input-full {
        flex: 76%;
    }

    span.input-full {
        flex: 82%;
    }

    .input-full input {
        flex: 42%;
        width: 40px;       /* to counter offset VUE3 default */
        min-width: 40px;    /* to counter offset VUE3 default */
        margin-right: 8px;
    }

    .input-full button {
        flex: 58%;
        font-family: Verdana, sans-serif;
        font-size: smaller;
    }

    .btn-container button {
        width: 68%;
        margin-top: 26px;
        padding: 10px;
    }
}


/* ************************************************************************************ */
/* below is the setting to take off the incremental/incremental spin from Number Input
/* For WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>
