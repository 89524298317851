<template>
    <div>
        <slot name="foreword"></slot> <!--slot 1-->

        <div id="frame-holder" v-show="visible_frame"><!-- shall not use v-if, otherwise the dialogue in the <iframe> will be lost, e.g. when toggling to and from the business page -->
            <slot name="widget"></slot> <!--slot 1.5-->
            
            <iframe id="page-frame" :style="{ height: iframeHeight }" :src="initUrl"></iframe>
        </div>

        <form v-show="email_subject" id="email-form" @submit.prevent="triggerSubmission">
            <span v-if="emailSent === 100"><i class="fa fa-check" style="color: green; font-weight: bold"></i></span>
            <input v-model="emailTo" id="email-input" type="email" placeholder="email addr." required />
            <span v-if="enable_passcode">
                <input v-model="passcode" id="passcode-input" type="number" placeholder="passcode" required />
                <button type="button" id="passcode-btn" @click="requestPasscode">Get</button>
            </span>
            <button type="submit" id="send-btn" :disabled="emailSent">Email</button>
            <button type="button" id="reset-btn" @click="resetIframe">Clear</button>
        </form>
  
        <slot name="afterword"></slot> <!--slot 2-->
    </div>
</template>

<script>
import { ref, onMounted, nextTick, watch } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

export default {
    name: "iframeCompo",
    props: {
        coid: {
            type: String,
            required: true,
        },
        email_subject: {        // if (email_subject) then the email bar shall be enabled
            type: String,
            required: false,
            default: '',
        },
        enable_passcode: {
            type: Boolean,
            required: false,
            default: true,
        },
        visible_frame: {
            type: Boolean,
            required: false,
            default: true,
        },
        lang: {
            type: String,
            required: false,
            default: '',
        },
    },
    setup(props) {
        const initUrl = `${process.env.VUE_APP_API_URL}/chat/html/aidi-func.html?com=${props.coid}&p-url=${encodeURIComponent(process.env.VUE_APP_BASE_URL)}`;    // p-url is the parent url for the html in the iframe, this is for security control that prevents talk to unknow source called the html

        /* let the JS in the <iframe> post a message that contains the document height, then change the height of the <iframe> here accordingly */
        /* this is mixed with 'about-us' toggle */
        //const iframeHeight = ref((window.innerWidth < 1024) ? '230px' : '180px');   // Initialize with a default or initial height
        const iframeHeight = ref('');   // Initialize with a default or initial height
        const emailSent = ref(0);      // 0: intact, 1~99: sending, 100: sent
        const emailTo = ref();
        const passcode = ref();

        const handleIframeMessage = (event) => {
            if (event.origin === process.env.VUE_APP_API_URL) {
                const { frame_height, html } = event.data;

                // Task 1: Update the height reactively
                if (frame_height && typeof frame_height === 'number' ) {
                    iframeHeight.value = `${frame_height + ((window.innerWidth < 1024 && !iframeHeight.value) ? 46 : 16)}px`;      // on my cellphone, the first newHeight is 313, which is not sufficient due to probably a calc bug of browsers
                    nextTick(() => {      // wait until the <iframe> is updated by ref, which is iframeHeight in this case
                        document.getElementById(props.email_subject ? 'email-form' : 'page-frame').scrollIntoView({ behavior: 'smooth', block: 'end' });
                        // for the web page to be properly rendered at initiation, default (email_subject) shall deemed to be false
                    });
                }

                // Task 2: Request the server to send out an email
                if (html) {
                    if (emailSent.value === 1) {
                        emailSent.value++;

                        fetch(`${process.env.VUE_APP_API_URL}/email`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ coid: props.coid, addr: emailTo.value, subject: props.email_subject, content: html, passcode: passcode.value })
                        })
                        .then(response => {
                            if (response.ok) {
                                emailSent.value = 100;
                            } else {
                                emailSent.value = 0;
                                alert(`IC01: ${response.status} - ${response.statusText}\n\nDetails: ${JSON.stringify(response.json())}`);
                            }
                        })
                        .catch(error => {       // catches the error from 'fetch'
                            emailSent.value = 0;
                            alert(`IC02: Request failed due to networking issues. Please try again later.\n\n${error.message}`);
                        });
                    }
                } else {        // any <iframe> height adjust event will reach this route
                    if (emailSent.value) {       // executes when it's as high as 100, stops at 0
                        emailSent.value--;
                    }
                }
            }
        };

        // function when language toggles
        // if without implementing 'watch' schema, this function won't work since v-if will not be ready before this triggering event
        // extract inline html from all <div class='gpt-greet'>, pass the html to iframe, and delete the <div> from its parent
        const initFrame = async () => {
            await nextTick();

            const iframe = document.getElementById('page-frame');
            const greets = document.querySelectorAll('.gpt-greet');
            if (greets.length) {        // > 0
                greets.forEach((div) => {         // supports multi .gpt-greet element
                    iframe.contentWindow.postMessage({ greeting: div.innerHTML }, process.env.VUE_APP_API_URL);
                    //div.parentElement.removeChild(div);     // Since div will be deleted, so make sure the <div> doesn't have v-if stuff otherwise it will cause runtime error (missing sth) such as when running toggleForm()
                });
            } else {
                iframe.contentWindow.postMessage({}, process.env.VUE_APP_API_URL);
            }
        }

        // If language changes, the parent caller might inject initiative '.gpt-greet' using v-if. This component shall WATCH this change and react using initFrame().
        // If without implementing 'watch' by using other tools, this schema won't work since v-if will not be ready before this triggering event
        /*watch(props.lang, async () => {       // this had been working perfect in ImmigView.vue, but here the 'watch' is just inactive.  
            await nextTick();       // Wait for the DOM to be updated after lang changes
            initFrame();
        });*/
        watch(() => props.lang, initFrame);     // TRIED all means! Using 'watch' scheme is a MUST. Now makes initFrame() async

        const requestPasscode = () => {
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailTo.value || !pattern.test(emailTo.value)) {
                alert(`IC11: Invalid email address.`);
                return;
            }

            fetch(`${process.env.VUE_APP_API_URL}/mfacode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ coid: props.coid, tag: emailTo.value })
            })
            .then(response => {
                if (response.ok) {
                    alert(`A passcode has been sent to your email box.\n\nIt expires in ${process.env.VUE_APP_PASSCODE_LIFECYCLE} minutes.`);
                } else {  
                    alert(`IC12: ${response.status} - ${response.statusText}\n\nDetails: ${JSON.stringify(response.json())}`);
                }
            })
            .catch(error => {
                alert(`IC13: Request failed due to networking issues. Please try again later.\n\n${error.message}`);
            });
        }

        // because the content in <iframe> cannot be extracted directly due to CORS, to the submission can only be triggered and going through a message posting train
        // the actual submission logic is handeled by handleIframeMessage() while responding messaging events bounced from <iframe>
        // but if there is none inner <iframe> in #gpt-messages, there won't be any message bouncing back. The chain stops within outer <iframe>
        const triggerSubmission = () => {
            if (!emailSent.value) {     // 0
                emailSent.value = 1;
                document.getElementById("page-frame").contentWindow.postMessage({ for_email: true }, process.env.VUE_APP_API_URL);      // this will trigger postEmail() in aidi-func.js in <iframe> after the browser renders the content html
            }
        };

        // reset the <iframe>. This is super useful for telling the server to start a new GPT thread.
        const resetIframe = () => {
            const iframe = document.getElementById("page-frame");
            iframe.src = initUrl;
            iframeHeight.value = null;      // force the <iframe> to shrink. Without setting it to 'null', it won't shrink.
            passcode.value = null;
            emailSent.value = 0;

            setTimeout(() => {      // nextTick doesn't work in this case
                document.getElementById("page-frame").contentWindow.postMessage({}, process.env.VUE_APP_API_URL);       // to resize the iframe window
            }, 180);
        }

        // to do when mounting
        onMounted(() => {
            document.getElementById('gpt-icon').style.display = 'none';
            document.getElementById('gpt-box').style.display = 'none';
            document.getElementById('about-us').style.display = 'none';
            window.addEventListener('message', handleIframeMessage);   // Set up the event listener for receiving messages from <iframe>

            // The <iframe> height is not properly calculated probably due to bugs in the browser, so the postHeight() in aidi-func.js needs to be called to trigger re-calc
            document.getElementById('page-frame').onload = () => {
                initFrame();
            }

            // to properly display the email bar on mobiles
            if (props.email_subject && window.innerWidth < 1024) {      // if props.email_subject true, document.getElementById('email-input') shall be true
                const p = document.createElement('p');
                //p.textContent = '\u200B';    // Zero Width Space
                document.getElementById('email-input').insertAdjacentElement("afterend", p);
            }
        });

        // to do at leaving
        onBeforeRouteLeave((to, from, next) => {
            document.getElementById('gpt-icon').style.display = 'block';
            document.getElementById('about-us').style.display = 'block';
            window.removeEventListener('message', handleIframeMessage);
            next();
        });

        return { initUrl, iframeHeight, emailSent, emailTo, passcode, requestPasscode, triggerSubmission, resetIframe };
    }
}
</script>

<style scoped>

/**************** */
/* iframe control */
/**************** */
#frame-holder {
    position: relative;
    margin-top: 24px;
}

iframe {
    margin: 0;
    padding: 0;
    width: 100%;
    border: 0;
    overflow: hidden;
}

/********* */
/* Form UI */
/********* */
#email-form {
    padding-bottom: 20px;
    text-align: right;
}

#email-form input {
    margin: 0 12px;
    padding: 8px 10px;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
    background-color: transparent;
    font-size: inherit;
}

#email-input {
    width: max-content;
    min-width: 160px;
}

#passcode-input {
    width: 60px;
    margin-right: 0 !important;
}

#email-form button {
    width: fit-content;
    margin: 0 10px;
    padding: 8px 12px;
    border: 1px solid transparent;
    border-bottom: 1px solid lightgray;
    border-radius: 3px;
    background-color: transparent;
    transition: color 0.2s, border-color 0.2s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    font-size: inherit;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

#passcode-btn {
    margin-left: 0 !important;
    border-left: 1px solid lightgray;
}

#email-form button:hover {
    border: 1px solid lightgray;
}

#email-form button:disabled {
    border-bottom: 1px solid transparent;
    cursor: not-allowed;
}

@media screen and (max-width: 1023px) {
    #email-form button {
        color: inherit;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    }
}


/* ************************************************************************************ */
/* below is the setting to take off the incremental/incremental spin from Number Input
/* For WebKit browsers (Chrome, Safari, etc.) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

</style>

<style>
h2 {
    margin-top: 16px;
}

/********************/
/* greeting control */
/********************/
.gpt-greet {
  display: none;
}
</style>