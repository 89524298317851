<template>
    <section id="biz-plan" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/bizplan'" />

        <iframeCompo :key="coid" coid="BizPlan" email_subject="Your business plan developed by AI-Directly Business Planning AI">
            <template #foreword>
                <h2>Business Planning AI</h2>
                <p>
                    This BizPlan AI functions like assigning two assistants to aid in your business planning. One is an erudite mentor who advises you through the complexities and unknowns, and the other is a diligent scribe who instantly transforms and elaborates the insights from your conversations into detailed chapters of your business plan. 
                </p>
                <p>
                    It also generates pitch deck slides directly from the business plan, facilitating an easy transition from planning to presentation with a solid first draft.
                </p>
                <div class="gpt-greet">
                    Let me begin by learning about your company background, products, services, market insights, and anything relevant.
                    <p>
                        Please paste the details or simply the source links, and I will retrieve the content from the websites if they are not anti-bot.
                    </p>
                </div>
            </template>

            <template #afterword>
                <p>
                    Once you're satisfied with any chapter of the business plan, you can request to have it sent to your email.
                </p>
                <p>
                    Please remain on this page while working on your business plan. Navigating away will drop the conversation content unless you are a paid user.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { ref } from 'vue';
import iframeCompo from "../components/iframeCompo.vue";
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'BizPlanView',
    components: {
        iframeCompo,
        pageCtrl
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');

        return { curLang };
    }
}
</script>

<style scoped>
</style>