<template>
    <section id="cv-builder" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/cvbuild'" />

        <iframeCompo :key="coid" coid="CVbuild" email_subject="Your resume built by AI-Directly Resume GPT">
            <template #foreword>
                <h2>Resume GPT</h2>
                <p>
                    This Resume Builder is an artificial intelligence that reviews your resume against job descriptions. It recommends keywords to add, tailors and refines your resume to better align with each specific job description and improve your chances of 
                    <iframeTooltip :tooltip_q="'What is ATS filter?'" :tooltip_a="'An ATS (Applicant Tracking System) filter is a software used by employers to streamline their recruitment process. It automates the initial stage of resume screening by sorting through applications and identifying those that match the job description based on specific keywords, skills, experience, and qualifications. Resumes that do not pass the ATS filter may not reach human recruiters for further review. Ensuring your resume is optimized with relevant keywords and formatted correctly can improve your chances of passing through an ATS filter.'">
                        <template #trigger>
                            <strong>passing ATS filters</strong>,
                        </template>
                    </iframeTooltip>
                    giving you an edge for any opportunity.
                </p>
                <p>
                    You paste job links, and Resume GPT will retrieve the websites and <strong>screens job postings</strong> to save you precious time off those not fitting your aspirations!
                </p>
                    Through interactive conversations, it will also craft your resume and cover letter from scratch.
                <p>
                </p>
                <div class="gpt-greet">
                    Let's review or build your resume in two steps:
                    <ol>
                        <li>
                            Paste your resume or enter your profile information, including skills, experience, education, certifications, etc., in any language.
                        </li>
                        <li>
                            Paste a job description or simply the job posting link, and I will analyze the website (if not anti-bot) and tailor your resume to match the JD and to perform better against ATS filters.
                        </li>
                    </ol>
                </div>
            </template>

            <template #afterword>
                <p>
                    Some websites, like LinkedIn and Monster, have anti-bot measures in place, which may prevent our AI from accessing them effectively.
                </p>
                <p>
                    Once you're satisfied with the resume, you can request to have it sent to your email.
                </p>
                <p>
                    Please remain on this page while we work on your resume. Navigating away will drop the conversation content.
                </p>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { ref } from 'vue';
import iframeCompo from "../components/iframeCompo.vue";
import iframeTooltip from "../components/iframeTooltip.vue";
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'CVbuildView',
    components: {
        iframeCompo,
        iframeTooltip,
        pageCtrl
    },
    setup() {
        // initialize the display language and whether the page be on post
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');

        return { curLang };
    }
}
</script>

<style scoped>
</style>