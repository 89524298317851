<template>
    <section id="immigrun" class="trunk">
        <pageCtrl v-model:lang="curLang" :page="'/immigrun'" />

        <iframeCompo :key="coid" coid="ImmigRun" :lang="curLang">
            <template #foreword>
                <div v-if="curLang === 'en'">
                    <h2>AI Immigration Consultant</h2>
                    <p>
                        Our GPT-powered immigration consultant possesses a thorough understanding of the laws, regulations, and policies that govern <strong>immigration</strong>, <strong>refugee matters</strong>, and <strong>studying abroad</strong> in Canada, Australia, New Zealand, and the United States. <!--He also has extensive resources for all kinds of services.-->
                    </p>
                    <p>
                        Its powerful <router-link to="/cvbuild">Resume GPT</router-link> helps you screen jobs for your aspirations, enhance resumes for <strong>ATS</strong> filtration, tailor resumes to individual job descriptions, and create resumes from conversational interactions.
                    </p>
                    <p>
                        Its erudite and diligent <router-link to="/bizplan">BizPlan AI</router-link> mentors you through business planning and also crafts the business plan for you. <!--both the business plan and pitch deck slides for you.-->
                    </p>
                    <div class="gpt-greet">
                        Hello! How can I assist you today?
                        <p>
                            Feel free to share your situation and ask any questions about immigration or schooling. I’m here to help you get started on the right foot and make well-informed decisions for your success.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'fr'">
                    <h2>Consultant en Immigration IA</h2>
                    <p>
                        Notre consultant en immigration alimenté par GPT possède une compréhension approfondie et complète des lois, réglementations et politiques régissant <strong>l'immigration</strong>, le statut de <strong>réfugié</strong>, les <strong>études à l'étranger</strong>, et les voyages internationaux au Canada, en Australie, en Nouvelle-Zélande et aux États-Unis.
                    </p>
                    <div class="gpt-greet">
                        Bonjour ! Comment puis-je vous aider aujourd'hui ?
                        <p>
                            Vous pouvez partager votre situation et poser toutes vos questions. Je vous aiderai à bien démarrer et à prendre des décisions éclairées pour votre réussite.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'cn'" class="lang-cn">
                    <h2>“润博士” AI 移民留学顾问</h2>
                    <p>
                        我们的人工智能移民顾问对加拿大、澳大利亚、新西兰和美国的移民和难民法、公民法、以及相关规定和政策有着深入且全面的掌握。<!--他也了解各种相关的服务资源。-->在此, 他将尽心解答您有关<u>移民</u>、<u>留学</u>、以及<u>难民身份</u>的任何问题。此外，他还熟知中国的《民法典》和《公司法》等法律法规，可以帮您对一些具体情况，比如涉及国籍等的各种问题，做法律层面的细致解读。
                    </p>
                    <p>
                        他强大的 <router-link :to="{ path: '/cvbuild', query: { lang: curLang } }">简历精灵</router-link> 帮您基于职业抱负筛选工作，通过零散的资料或者中文简历来创建英文简历。针对不同的企业和工作，帮您定向裁剪简历以契合每一份工作要求，并提高简历<u>通过 ATS 筛选</u>的几率。
                    </p>
                    <p>
                        他博学勤勉的 <router-link to="/bizplan">生意规划人工智能</router-link> 指导您进行商业规划，同时又为您制作完成商业计划书。
                    </p>
                    <div class="gpt-greet">
                        您好！欢迎咨询任何移民和留学问题！请告知您的详情, 我来帮您深入分析并优选规划，助您顺利实现梦想。
                    </div>
                </div>
                <div v-if="curLang === 'tcn'" class="lang-cn">
                    <h2>AI 移民留學顧問</h2>
                    <p>
                        我們的人工智慧移民顧問對<u>加拿大</u>、<u>澳洲</u>、<u>紐西蘭</u>和<u>美國</u>的移民和難民法、公民法，以及相關規定和政策有著深入且全面的掌握。<!--他也了解各種相關的服務資源。-->在此，他將盡心解答您有關移民、留學、難民身份，以及跨國旅行的任何問題。
                    </p>
                    <div class="gpt-greet">
                        您好！歡迎諮詢任何移民和留學問題！請詳細描述您的情況，我會幫您深入分析並優選規劃，助您順利實現夢想。
                    </div>
                </div>
                <div v-if="curLang === 'kor'">
                    <h2>AI 이민 유학 상담사</h2>
                    <p>
                        저희 인공지능 이민 상담사는 캐나다, 호주, 뉴질랜드, 그리고 미국의 이민 및 난민법, 시민법, 관련 규정 및 정책에 대해 깊고 포괄적인 지식을 가지고 있습니다. 여기서, 이민, 유학, 난민 신분, 그리고 국제 여행에 관한 모든 질문에 성심껏 답변해 드리겠습니다.
                    </p>
                    <div class="gpt-greet">
                        안녕하세요! 이민 및 유학에 관한 모든 질문을 환영합니다! 상황을 자세히 설명해 주시면, 저는 심층 분석과 최적의 계획을 도와드리겠습니다. 꿈을 이루는 데에 성공적으로 나아가실 수 있도록 돕겠습니다.
                    </div>
                </div>
                <div v-if="curLang === 'es'">
                    <h2>Consultor de Inmigración IA</h2>
                    <p>
                        Nuestro consultor de inmigración impulsado por GPT posee un conocimiento profundo y completo de las leyes, regulaciones y políticas que rigen la <strong>inmigración</strong>, el estatus de <strong>refugiado</strong>, los <strong>estudios en el extranjero</strong> y los viajes internacionales en Canadá, Australia, Nueva Zelanda y los Estados Unidos.
                    </p>
                    <div class="gpt-greet">
                        ¡Hola! ¿En qué puedo ayudarte hoy?
                        <p>
                            Puedes compartir tu situación y hacer cualquier pregunta. Te ayudaré a comenzar con el pie derecho y a tomar decisiones informadas para tu éxito.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'pt'">
                    <h2>Consultor de Imigração IA</h2>
                    <p>
                        Nosso consultor de imigração baseado em GPT possui um conhecimento profundo e abrangente das leis, regulamentos e políticas que regem a <strong>imigração</strong>, o status de <strong>refugiado</strong>, os <strong>estudos no exterior</strong> e as viagens internacionais no Canadá, Austrália, Nova Zelândia e Estados Unidos.
                    </p>
                    <div class="gpt-greet">
                        Olá! Como posso te ajudar hoje?
                        <p>
                            Você pode compartilhar sua situação e fazer qualquer pergunta. Vou te ajudar a começar bem e tomar decisões bem-informadas para o seu sucesso.
                        </p>
                    </div>
                </div>
                <div v-if="curLang === 'far'" class="lang-far">
                    <h2>مشاور هوش مصنوعی برای مهاجرت و تحصیل در خارج</h2>
                    <p>
                        مشاور مهاجرت ما که بر اساس GPT عمل می‌کند، دارای درک کامل و جامع از قوانین، مقررات و سیاست‌های مربوط به <strong>مهاجرت</strong>، وضعیت <strong>پناهندگی</strong>، <strong>تحصیل در خارج</strong> و سفرهای بین‌المللی به کانادا، استرالیا، نیوزیلند و ایالات متحده است.
                    </p>
                    <div class="gpt-greet">
                        سلام! چطور می‌توانم امروز به شما کمک کنم؟
                        <p>
                            می‌توانید وضعیت خود را به اشتراک بگذارید و هر سؤالی که دارید بپرسید. من به شما کمک می‌کنم که کار خود را با موفقیت شروع کنید و تصمیمات آگاهانه‌ای برای موفقیت خود بگیرید.
                        </p>
                    </div>
                </div>
            </template>

            <!-- Add a stamp of 'FREE' on the <iframe> -->
            <!--template #widget> 
                <img id="stamp" src="/img/FREE.png" alt="FREE" />
            </template-->

            <template #afterword>
                <div v-if="curLang === 'en'">
                    <!--p>
                        This AI immigration consultant has a <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad" target="_blank" rel="noopener noreferrer" @click.stop="">clone</a> accessible through OpenAI ChatGPT.
                    </p-->
                    <p>
                        For your privacy and data security, please refrain from sharing any personal identification information.
                    </p>
                    <h3>Business Partnership</h3>
                    <p>
                        For businesses serving new immigrants and international students, please click <a href="#" @click.prevent="handleRoute">HERE</a> to list your services.
                    </p>
                    <h3>Disclaimer</h3>
                    <p>
                        This digital immigration consultant is powered by AI, specifically the GPT model, and has been trained on relevant legal texts, government program materials, and service market data. It provides immigration advice and solutions. However, as an AI but a human being, it is not eligible to obtain the licenses and certifications required for lawyers, registered consultants, or licensed advisors in the immigration field. Please exercise caution when considering the advice and solutions offered. AI Directly, the creator of this AI immigration consultant, assumes no responsibility for outcomes resulting from your reliance on its information output.
                    </p>
                </div>
                <div v-if="curLang === 'fr'">
                    <p>
                        Pour votre confidentialité et la sécurité de vos données, veuillez ne pas partager d'informations d'identification personnelle.
                    </p>
                    <h3>Partenariat Commercial</h3>
                    <p>
                        Pour les entreprises desservant les nouveaux immigrants et les étudiants internationaux, veuillez cliquer <a href="#" @click.prevent="handleRoute">ICI</a> pour lister vos services.
                    </p>
                    <h3>Avertissement</h3>
                    <p>
                        Ce consultant en immigration numérique est alimenté par l'IA, spécifiquement le modèle GPT, et a été formé sur des textes juridiques pertinents, des matériaux de programmes gouvernementaux et des données de marché des services. Il fournit des conseils et des solutions en matière d'immigration. Cependant, étant une IA et non un être humain, il n'est pas éligible pour obtenir les licences et certifications requises pour les avocats, les consultants enregistrés ou les conseillers agréés dans le domaine de l'immigration. Veuillez faire preuve de prudence lors de la prise en compte des conseils et des solutions proposés. AI Directly, le créateur de ce consultant en immigration IA, n'assume aucune responsabilité pour les résultats découlant de votre dépendance à ses informations.
                    </p>
                </div>
                <div v-if="curLang === 'cn'" class="lang-cn">
                    <!--p>
                        这个人工智能移民顾问还可以通过 OpenAI ChatGPT 上的 <a href="https://chatgpt.com/g/g-5jA5DOoao-yi-min-liu-xue-nan-min-lu-xing-qian-zheng-zhong-guo-min-fa-dian-he-gong-si-fa-deng" target="_blank" rel="noopener noreferrer" @click.stop="">副本</a> 进行访问。
                    </p-->
                    <p>
                        为了保护您的隐私和数据安全，请避免提供任何个人真实身份信息。比如可以用“张三”替代真实姓名。
                    </p>
                    <h3>业务伙伴</h3>
                    <p>
                        服务于移民留学人群的上下游公司请按 <a href="#" @click.prevent="handleRoute"><u>这里</u></a> 发布服务内容。
                    </p>
                    <h3>免责声明</h3>
                    <p>
                        这位数字移民顾问由人工智能 GPT 模型驱动, 精通诸多相关法律和政府移民计划，掌握相关服务市场方面的大量信息。它给您提供移民和留学方面的建议和方案。然而, 作为一个人工智能而非人类, 它无缘获得律师资格或注册移民顾问资格。所以请谨慎参考它提供的建议和方案。AI Directly, 这位人工智能移民顾问的制造者，不承担因您依赖其信息输出而产生的任何后果。
                    </p>
                </div>
                <div v-if="curLang === 'tcn'" class="lang-cn">
                    <!--p>
                        這個人工智慧移民顧問還可以透過 OpenAI ChatGPT 上的 <a href="https://chatgpt.com/g/g-xM3dkPi0v-immigration-study-abroad" target="_blank" rel="noopener noreferrer" @click.stop="">副本</a> 進行訪問。
                    </p-->
                    <p>
                        為了保護您的隱私和資料安全，請避免提供任何個人的真實身份資訊。您可以使用“張三”來代替真實姓名。
                    </p>
                    <h3>業務夥伴</h3>
                    <p>
                        服務新移民與留學生的上下游企業請按 <a href="#" @click.prevent="handleRoute"><u>這裡</u></a> 列出您的服務內容。
                    </p>
                    <h3>免責聲明</h3>
                    <p>
                        這位數位移民顧問由人工智慧GPT模型驅動，精通諸多相關法律及政府移民計劃，掌握相關服務市場方面的大量資訊。它為您提供移民和留學方面的建議和方案。然而，作為一個人工智慧而非人類，它無法獲得律師資格或註冊移民顧問資格。因此請謹慎參考它提供的建議和方案。AI Directly，這位人工智慧移民顧問的製造者，不承擔因您依賴其資訊輸出而產生的任何後果。
                    </p>
                </div>
                <div  v-if="curLang === 'kor'">
                    <p>
                        고객님의 개인정보와 데이터 보안을 위해, 개인 신원 정보를 제공하지 않도록 주의해 주세요.
                    </p>
                    <h3>비즈니스 파트너</h3>
                    <p>
                        이민자 및 유학생을 위한 서비스를 제공하는 기업은 <a href="#" @click.prevent="handleRoute">여기</a>를 클릭하여 서비스를 등록해 주세요.
                    </p>
                    <h3>면책 조항</h3>
                    <p>
                        이 디지털 이민 컨설턴트는 인공지능 GPT 모델로 구동되며, 관련 법률과 정부의 이민 프로그램을 숙지하고 관련 서비스 시장에 대한 방대한 정보를 가지고 있습니다. 이를 통해 이민 및 유학에 관한 조언과 솔루션을 제공합니다. 그러나 인공지능으로서 변호사 자격이나 등록된 이민 컨설턴트 자격을 얻을 수는 없습니다. 따라서 제공되는 조언과 솔루션을 신중히 고려하시기 바랍니다. AI Directly, 이 AI 이민 컨설턴트의 제조사는 정보 의존 결과에 대해 어떠한 책임도 지지 않습니다.
                    </p>
                </div>
                <div  v-if="curLang === 'es'">
                    <p>
                        Para proteger su privacidad y seguridad de datos, por favor no comparta ninguna información de identificación personal.
                    </p>
                    <h3>Asociación Comercial</h3>
                    <p>
                        Para las empresas que atienden a nuevos inmigrantes y estudiantes internacionales, haga clic <a href="#" @click.prevent="handleRoute">AQUÍ</a> para listar sus servicios.
                    </p>
                    <h3>Descargo de responsabilidad</h3>
                    <p>
                        Este consultor de inmigración digital está impulsado por IA, específicamente por el modelo GPT, y ha sido entrenado en textos legales relevantes, materiales de programas gubernamentales y datos del mercado de servicios. Proporciona asesoramiento y soluciones sobre inmigración. Sin embargo, como una IA y no un ser humano, no es elegible para obtener las licencias y certificaciones requeridas para abogados, consultores registrados o asesores licenciados en el campo de la inmigración. Por favor, ejerza precaución al considerar los consejos y soluciones ofrecidos. AI Directly, el creador de este consultor de inmigración IA, no asume ninguna responsabilidad por los resultados derivados de su confianza en su información.
                    </p>
                </div>
                <div  v-if="curLang === 'pt'">
                    <p>
                        Para garantir sua privacidade e a segurança de seus dados, por favor, não compartilhe nenhuma informação de identificação pessoal.
                    </p>
                    <h3>Parceria Comercial</h3>
                    <p>
                        Para empresas que atendem novos imigrantes e estudantes internacionais, clique <a href="#" @click.prevent="handleRoute">AQUI</a> para listar seus serviços.
                    </p>
                    <h3>Aviso Legal</h3>
                    <p>
                        Este consultor de imigração digital é alimentado por IA, especificamente pelo modelo GPT, e foi treinado em textos legais relevantes, materiais de programas governamentais e dados do mercado de serviços. Ele oferece conselhos e soluções de imigração. No entanto, como uma IA e não um ser humano, ele não é elegível para obter as licenças e certificações necessárias para advogados, consultores registrados ou conselheiros licenciados na área de imigração. Por favor, tenha cautela ao considerar os conselhos e soluções oferecidos. AI Directly, a criadora deste consultor de imigração IA, não assume nenhuma responsabilidade pelos resultados decorrentes da sua dependência das informações fornecidas.
                    </p>
                </div>
                <div v-if="curLang === 'far'" class="lang-far">
                    <p>
                        برای حفظ حریم خصوصی و امنیت اطلاعات شما، لطفاً از به اشتراک گذاشتن هرگونه اطلاعات شخصی خودداری کنید.
                    </p>
                    <h3>همکاری تجاری</h3>
                    <p>
                        برای کسب‌وکارهایی که به مهاجران جدید و دانشجویان بین‌المللی خدمات ارائه می‌دهند، لطفاً برای فهرست کردن خدمات خود <a href="#" @click.prevent="handleRoute">اینجا</a> کلیک کنید.
                    </p>
                    <h3>سلب مسئولیت</h3>
                    <p>
                        این مشاور مهاجرت دیجیتال توسط مدل هوش مصنوعی GPT به کار گرفته شده است، در قوانین مربوطه و برنامه‌های دولتی مهاجرت تبحر دارد و اطلاعات گسترده‌ای در مورد بازار خدمات مرتبط دارد. این سیستم پیشنهادات و راه‌حل‌هایی در زمینه مهاجرت و تحصیل در خارج ارائه می‌دهد. با این حال، به عنوان یک هوش مصنوعی و نه انسان، واجد شرایط دریافت مجوزهای لازم برای وکلا یا مشاوران مهاجرت ثبت‌شده نیست. بنابراین لطفاً در نظر گرفتن پیشنهادات و راه‌حل‌های ارائه شده احتیاط کنید. AI Directly، سازنده این مشاور مهاجرت AI، هیچ مسئولیتی در قبال عواقب ناشی از تکیه بر اطلاعات آن ندارد.
                    </p>
                </div>
            </template>
        </iframeCompo>
    </section>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import iframeCompo from "../components/iframeCompo.vue";
import pageCtrl from "../components/pageCtrl.vue";

export default {
    name: 'ImmigView',
    components: {
        iframeCompo,
        pageCtrl
    },
    setup() {
        // The language display is pretty complicated
        // 1. Display in languages is controlled by variable curLang and v-if
        // 2. if the language is cn or tcn, the CSS will let the space between characters be wider through the class lang-cn
        // 3. if the language is far, the CSS wil let the font be larger through the class lang-far
        // 4. if the language is cn or tcn, labels and buttons will be shown in Chinese due to variable cnDisp and v-if, while cnDisp is set by @change script in <select>
        const queryParams = new URLSearchParams(window.location.search);
        const curLang = ref(queryParams.get('lang') || 'en');

        // The following is specifically for accommodating immigrun.com
        const page = queryParams.get('page');
        if (['cvbuild', 'bizplan', 'coupon', 'immigform'].includes(page)) {
            useRouter().push({
                path: `/${page}`,
                query: { lang: (curLang.value === 'en') ? undefined : curLang.value },
            });
        }

        return { curLang };
    },
}
</script>

<style scoped>

/************************************************ */
/* Language control now is moved to pageCtrl.vue  */
/************************************************ */


/**************** */
/* iframe control */
/**************** */
#stamp {
    position: absolute; 
    top: -18px; 
    right: 48px; 
    z-index: 10; 
    width: 60px; 
    height: 56px;
}

@media screen and (max-width: 1023px) {
    #stamp {
        top: -14px;
        width: 52px; 
        height: 48px;
    }
}
</style>
